import { useContext } from 'react';

import { MapContext } from '../EngrainMap';

interface UnitProps {
  unitIds: string[];
  color: string;
  selectedColor: string;
}

const Units = (props: UnitProps) => {
  const context = useContext(MapContext);
  const { map, units } = context;
  const { color, selectedColor, unitIds } = props;

  if (!map && !units) return null;
  (units as any).color(color);

  for (let i = 0; i < unitIds.length; i++) {
    (units as any).find(unitIds[i]).color(selectedColor);
  }

  return null;
};

export default Units;
